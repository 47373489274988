"use strict";

// Apexcharts - mBdern charting library that helps developers to create beautiful and interactive visualizations for web pages: https://apexcharts.com/
window.ApexCharts = require('apexcharts/dist/apexcharts.min.js');

// Class definition
var KTCardsWidget10 = function () {
    // Private methods
    var initChart = function() {
        var el = document.getElementById('kt_card_widget_10_chart'); 
        const readItems = document.querySelectorAll(".read-widget-10 > div > .read-node");
        var totalItems = 0;

        readItems.forEach((item) => {
            totalItems += parseInt(item.innerHTML);
        });

        if (!el) {
            return;
        }

        var options = {
            size: el.getAttribute('data-kt-size') ? parseInt(el.getAttribute('data-kt-size')) : 70,
            lineWidth: el.getAttribute('data-kt-line') ? parseInt(el.getAttribute('data-kt-line')) : 11,
            rotate: el.getAttribute('data-kt-rotate') ? parseInt(el.getAttribute('data-kt-rotate')) : 145,            
        }

        var canvas = document.createElement('canvas');
        var span = document.createElement('span'); 
            
        if (typeof(G_vmlCanvasManager) !== 'undefined') {
            G_vmlCanvasManager.initElement(canvas);
        }

        var ctx = canvas.getContext('2d');
        canvas.width = canvas.height = options.size;

        el.appendChild(span);
        el.appendChild(canvas);

        ctx.translate(options.size / 2, options.size / 2); // change center
        ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI); // rotate -90 deg

        var radius = (options.size - options.lineWidth) / 2;

        var drawCircle = function(color, lineWidth, percent, startAngle) {
            ctx.beginPath();
            ctx.arc(0, 0, radius, startAngle, Math.PI * 2 * percent + startAngle, false);
            ctx.strokeStyle = color;
            ctx.lineCap = 'squary'; // butt, round or square
            ctx.lineWidth = lineWidth
            ctx.stroke();
        };

        // Initial animation
        const assignableColors = [
            KTUtil.getCssVariableValue('--bs-success'),
            KTUtil.getCssVariableValue('--bs-primary'),
            '#E4E6EF',
        ];

        var startAngle = 0;
        readItems.forEach((item, key) => {
            drawCircle(
                assignableColors[key],
                options.lineWidth,
                parseInt(item.innerHTML) / totalItems,
                startAngle
            );
            startAngle += Math.PI * 2 * parseInt(item.innerHTML) / totalItems;
        });
    }

    // Public methods
    return {
        init: function () {
            initChart();
        }   
    }
}();

// Webpack support
if (typeof module !== 'undefined') {
    module.exports = KTCardsWidget10;
}

// On document ready
KTUtil.onDOMContentLoaded(function() {
    KTCardsWidget10.init();
});
   
        
        
        
           